.wrapper {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.label {
  color: #000000;
  font-size: 14px;
  font-family: 'Oxygen';
}

.error {
  color: #ff0000;
  font-size: 12px;
}
